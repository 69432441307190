<template>
  <span class="q-pa-md">Redirecting...</span>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { APP_ROUTER_STORE_KEY } from '@/constants/vars'
import { localStore } from '@/utils/localstore'

@Options({
  components: {},
  directives: { maska },
})
export default class SlackRedirect extends Vue {
  get slackCode() {
    return this.$route.query.code
  }

  created() {
    this.$meta.setMeta({ title: ['Redirecting'] })
    const redirectUrl = localStore.getItem(APP_ROUTER_STORE_KEY.SLACK_REDIRECT_URL)
    if (redirectUrl) {
      this.$router.push(`${redirectUrl}?code=${this.slackCode}`)
    } else {
      this.$router.push({ name: 'home' })
    }
  }
}
</script>
